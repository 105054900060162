import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Anlam from 'anlam';

export default function Template({
  data, path // this prop will be injected by the GraphQL query below.
}) {

  let pathname = "/arama/";
  if(typeof document != "undefined"){
    pathname = document.location.pathname;
  }

  let term = decodeURI(pathname.split("/")[2].replace(/-/g, " "));
  let title = term ? term[0].toLocaleUpperCase() + term.substr(1, term.length - 1) : "";
  let page = pathname.split("/")[3] ? pathname.split("/")[3] : 1;

  const [results, setResults] = useState([]);
  const fetchResults = (searchTerm) => {
    fetch(`https://api.etimolojiturkce.com/searchdetailed/${encodeURI(searchTerm)}`).then(res => res.json()).then((result) => setResults(result));
  }

  useEffect(() => {
    fetchResults(term);
  }, [term]);

  return (
    <Layout>
      <h1>{title}</h1>
      <p>Aradığınız kelimenin içinde geçen kelime sonuçlarını aşağıda görebilirsiniz.</p>

      {results && results.map((res, i) => {
        return(
          <div key={i}>
            <Link to={`/kelime/${res.kelime}`}><h3>{res.kelime}</h3></Link>
            <p dangerouslySetInnerHTML={{__html: res.koken}}></p>
          </div>
        )
      })}

      {results.length == 0 &&
        <p className="box">{term.length <= 2 ? "Detaylı arama yapmak için 3 veya daha fazla karakter içeren bir arama kelimesi girmelisiniz." : "Aradığınız kelimeyle ilgili veritabanında kayıtlı bir kelime bulunamadı."}</p>
      }
      {results.length != 0 &&
        <p className="box">Bu arama sonucu toplam <b>{results.length}</b> sonuç bulundu!</p>
      }

      <SEO
        title={`${title} Kelime Kökeni, Kelimesinin Anlamı - Etimoloji`}
        description={`${title} kelimesinin anlamı, kökeni ve etimolojik incelemesi. ${title} kelimesinin geçtiği en eski kaynak ve kelime etimolojisi, ${title} ne demek?`}
        meta={[
          {name: "canonical", content: `https://www.etimolojiturkce.com/arama/${term.replace(/ /g, "-")}`}
        ]}
        />
    </Layout>
  )
}
