import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import getOS from '../getOS';

const Footer = ({ isHome }) => {
  let OS = getOS();

  return (
    <>
      {!isHome &&
        <div className="apps">
          <div className="box">
            <h3>Mobil Uygulama</h3>
            <p>Uygulama marketlerindeki <b>tamamen ücretsiz</b> Etimoloji uygulamamızı indirebilirsiniz.</p>
            {OS != "Android" &&
              <a href="https://apps.apple.com/tr/app/etimoloji-kelime-k%C3%B6keni/id1406420546" target="_blank">
                <img src="/as.png" alt="App Store" style={{width: 150}}/>
              </a>
            }
            {OS != "iOS" &&
              <a href="https://play.google.com/store/apps/details?id=com.etimolojiturkce.app&hl=tr" target="_blank">
                <img src="/gp.png" alt="Google Play" style={{width: 150}}/>
              </a>
            }
          </div>
        </div>
      }
      <div className="footer">
        <div className="footerInner">
          <div className="source">
            <div style={{width: 88}}>
              <a rel="license" href="https://creativecommons.org/licenses/by-nc-sa/4.0/">
                <img alt="Creative Commons License" src="/cc.png" width={88} height={31} alt="Creative Commons"/>
              </a>
            </div>
            <div style={{width: "calc(100% - 100px)"}}>
              <p>
                <b>© 2012 - 2020</b> derleme içerik <a target="_blank" href="https://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Lisansı</a> ile lisanslıdır. Buna göre site içeriğini ticari olmayan çalışmalarınız için kullanabilir ve kaynak gösterebilirsiniz.
              </p>
            </div>
          </div>
          <p className="subtext">Site içeriği Wiktionary, NişanyanSözlük, TRT Telaffuz, etymologeek ve Etymonline içeriğine ve çevirilerine dayanmaktadır.</p>
        </div>
      </div>
    </>
  )
}

export default Footer
