import { Link, navigate } from "gatsby"
import React, { useState, useRef } from "react"
import _ from "lodash";

const Header = () => {
  const [term, setTerm] = useState("");
  const [results, setResults] = useState();
  const searchInput = useRef(null);
  const handleTermChangeThrottle = useRef(_.debounce(q => fetchResults(q), 400)).current;

  const fetchResults = (term) => {
    fetch(`https://api.etimolojiturkce.com/search/${encodeURI(term)}`).then(res => res.json()).then((result) => setResults(result));
  }

  const handleTermChange = (event) => {
    let searchTerm = event.target.value;
    setTerm(searchTerm);
    if(searchTerm.length >= 2){
      handleTermChangeThrottle(searchTerm);
    }else{
      setResults();
    }
  }

  const handleBlur = () => {
    setTimeout(() => {
      setResults();
    }, 100);
  }

  const handleFocus = () => {
    if(term.length >= 2){
      fetchResults(term);
    }else{
      setResults();
    }
  }

  const handleSubmit= (e) => {
    e.preventDefault();
    searchInput.current.blur();
    navigate(`/arama/${term}`);
  }

  const renderWord = (word) => {
    if(word.endsWith(1)){
      return word.substr(0, word.length - 1);
    }else if(word.endsWith(2)){
      return (<>{word.substr(0, word.length - 1)}<span>(Yan anlam)</span></>);
    }else if(word.endsWith(3)){
      return (<>{word.substr(0, word.length - 1)}<span>(3)</span></>);
    }else{
      return word;
    }
  }

  return (
    <header>
      <div className="header">
        <div className="headerCarrier">
          <div className="search">
            <form onSubmit={handleSubmit}>
              <input type="text"
                id="searchBox"
                ref={searchInput}
                placeholder="Kelimelerin kökenini arayın..."
                onChange={handleTermChange}
                value={term}
                className={results ? "autofilled"+results["return"]: ""}
                onBlur={handleBlur}
                onFocus={handleFocus}/>
              <svg style={{width: 20, height: 20, position: "absolute", top: 10, left: 15}} viewBox={"0 0 30 30"}>
                <g fill="#fff">
                  <path d="M13,25.93 C5.871,25.93 0.07,20.129 0.07,13 C0.07,5.871 5.871,0.07 13,0.07 C20.129,0.07 25.93,5.871 25.93,13 C25.93,20.129 20.129,25.93 13,25.93 L13,25.93 Z M13,1.93 C6.896,1.93 1.93,6.896 1.93,13 C1.93,19.104 6.896,24.07 13,24.07 C19.104,24.07 24.07,19.104 24.07,13 C24.07,6.896 19.104,1.93 13,1.93 L13,1.93 Z"/>
                  <polyline points="30.293 31.707 21.293 22.707 22.707 21.293 31.707 30.293 30.293 31.707"/>
                </g>
              </svg>
              <div className="results">
                {results &&
                  <ul>
                    {Object.values(results).sort((a, b) => a.length - b.length).map((res, i) => {
                      if(res.length > 1){
                        return (<Link key={i} to={`/kelime/${res}`}><li>{renderWord(res)}</li></Link>);
                      }
                    })}
                  </ul>
                }
              </div>
              <div className="shadow"></div>
            </form>
          </div>
        </div>
      </div>
      <div style={{height: 60}}></div>
    </header>
  )
}

export default Header
